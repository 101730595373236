import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { PageFullUrls, PageRoute } from '../interfaces/site-config.interface';
import { SiteConfigService } from '../site-config/site-config.service';
import { TEMPLATES } from './templates';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(
    private readonly siteSvc: SiteConfigService,
    private readonly router: Router
  ) {}

  homeId!: string | number;

  destinyParentParemeterId = 0;
  modelId = 0;

  setModelId(modelId: string | number): void {
    this.modelId = Number(modelId);
  }

  setDestinyParentParemeterId(id: number) {
    this.destinyParentParemeterId = id;
  }

  setSettingsRouter(pages: PageFullUrls[]) {
    const basicRoutes: Routes = [
      {
        path: '',
        redirectTo: pages
          .find((page) => page.route.some((route) => route.url.length === 4))
          ?.route?.find(
            (route) =>
              route?.languageId === this.siteSvc.selectedLanguageInstant?.id
          )
          ?.url.slice(0, -1),
        pathMatch: 'full', // Ensure pathMatch is 'full' to avoid partial matches
      },
      {
        path: '**',
        pathMatch: 'full',
        loadComponent: () =>
          import('../../pages/not-found/not-found.component').then(
            (m) => m.NotFoundComponent
          ),
      },
    ];

    if (pages) {
      this.homeId = pages.find(
        (page) => page.template === TEMPLATES.GENERAL
      )?.id!;
      let routerItems: Routes = [];
      pages.forEach((page) => {
        page.route?.forEach((route) => {
          if (route.languageId && route.url) {
            routerItems.push(this.convertSitemapItem2Route(route, page));
          }
        });
      });
      this.router.config = routerItems.concat(basicRoutes);

      setTimeout(() => {
        const pageGlobal = pages.find((page) =>
          page?.route?.find(
            (route: PageRoute) =>
              this.router.url.includes(route.url) && route.global
          )
        );

        if (pageGlobal) {
          this.checkRedirection(pageGlobal);
        }
      }, 500);
    }
  }

  checkRedirection(page: PageFullUrls): void {
    this.router.navigateByUrl(
      page?.route?.find(
        (route) => route.languageId === this.siteSvc.selectedLanguageInstant?.id
      )!.url
    );
  }

  convertSitemapItem2Route(route: PageRoute, page: PageFullUrls): Route {
    let url = route.url;
    if (route.url.charAt(route.url.length - 1) === '/') {
      url = url.slice(1, -1);
    }
    return {
      path: url,
      loadComponent: () => this.getComponent(page.template),
    };
  }

  private checkCurrentRouteParams(): string {
    let mainRoute = this.router.url;
    if (mainRoute.includes('#')) {
      mainRoute = mainRoute.split('#')[0];
    } else if (mainRoute.includes('?')) {
      mainRoute = mainRoute.split('?')[0];
    }
    mainRoute = mainRoute.slice(1);

    return mainRoute;
  }

  getPageId(): string | number | undefined {
    this.modelId = 0;
    let mainRoute = this.checkCurrentRouteParams();

    if (mainRoute === '') {
      return this.homeId;
    }

    return this.siteSvc.webConfigInstant?.pages?.find((page) => {
      return page?.route?.find((route) => `\/${mainRoute}\/` === route?.url);
    })?.pageId;
  }

  getModelId(): number | undefined {
    let mainRoute = this.checkCurrentRouteParams();
    const route = this.siteSvc.webConfigInstant?.pages?.find((page) =>
      page?.route?.find((route) => `\/${mainRoute}\/` === route?.url)
    );

    if (this.modelId !== route?.modelId && route?.modelId) {
      this.modelId = route?.modelId;
    }

    return this.modelId;
  }

  getPageIdByTemplate(template: string): number | string | undefined {
    this.modelId = 0;
    return this.siteSvc.webConfigInstant?.pages?.find(
      (page) => page.template?.toLowerCase() === template?.toLowerCase()
    )?.pageId;
  }

  switchUrlDiferentLanguage(templateUrl: string) {
    return this.siteSvc.pages
      ?.find((page) =>
        page.route?.some((route) => route.url === templateUrl.slice(1))
      )
      ?.route.find(
        (route) => route.languageId == this.siteSvc.selectedLanguageInstant?.id
      )?.url;
  }

  getComponent(template: string) {
    switch (template.toLowerCase()) {
      case TEMPLATES.GENERAL.toLowerCase():
        return import('../../pages/dynamic-page/dynamic-page.component').then(
          (m) => m.DynamicPageComponent
        );

      case TEMPLATES.HOTEL.toLowerCase():
        return import('../../pages/dynamic-page/dynamic-page.component').then(
          (m) => m.DynamicPageComponent
        );

      case TEMPLATES.WEBMAP.toLowerCase():
        return import('../../pages/web-map/web-map.component').then(
          (m) => m.WebMapComponent
        );

      case TEMPLATES.FAQ.toLowerCase():
        return import('../../pages/faq/faq.component').then(
          (m) => m.FaqComponent
        );

      case TEMPLATES.CONTACT.toLowerCase():
        return import('../../pages/contact/contact.component').then(
          (m) => m.ContactComponent
        );

      case TEMPLATES.GALLERY.toLowerCase():
        return import('../../pages/gallery/gallery.component').then(
          (m) => m.GalleryComponent
        );

      case TEMPLATES.THANKS.toLowerCase():
        return import('../../pages/thanks/thanks.component').then(
          (m) => m.ThanksComponent
        );

      case TEMPLATES.LEGAL.toLowerCase():
        return import('../../pages/legal/legal.component').then(
          (m) => m.LegalComponent
        );

      case TEMPLATES.LIST_PROMOS.toLowerCase():
        return import('../../pages/offers/offers.component').then(
          (m) => m.OffersComponent
        );

      case TEMPLATES.LIST_PROMOS_V2.toLowerCase():
        return import('../../pages/offers-v2/offers-v2.component').then(
          (m) => m.OffersV2Component
        );

      case TEMPLATES.ROOMS.toLowerCase():
        return import('../../pages/room-list/room-list.component').then(
          (m) => m.RoomListComponent
        );

      case TEMPLATES.ROOM_DETAIL.toLowerCase():
        return import('../../pages/room-detail/room-detail.component').then(
          (m) => m.RoomDetailComponent
        );

      case TEMPLATES.PROMO_DETAIL.toLowerCase():
        return import('../../pages/offers-detail/offers-detail.component').then(
          (m) => m.OffersDetailComponent
        );

      default:
        return import('../../pages/not-found/not-found.component').then(
          (m) => m.NotFoundComponent
        );
    }
  }
}
