import { Injectable, Renderer2, signal } from '@angular/core';
import {
  AuroraEngineI,
  AuroraEngineModalI,
  AuroraHeaderI,
  AuroraHeaderMobileI,
  AuroraStaticFooterI,
  AuroraStaticFooterV2I,
} from 'ctt-aurora';
import { Utils } from '../../Utils/utils';
import {
  BodyComponent,
  PageConfig,
  PageConfigHead,
} from '../interfaces/page-config.interface';

declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly headerData = signal<
    AuroraHeaderI | AuroraHeaderMobileI | undefined
  >(undefined);
  private readonly engineData = signal<
    AuroraEngineI | AuroraEngineModalI | undefined
  >(undefined);

  readonly footer = signal<
    BodyComponent<AuroraStaticFooterI | AuroraStaticFooterV2I> | undefined
  >(undefined);

  private readonly pageHead = signal<PageConfigHead | undefined>(undefined);

  private readonly dynamicHeader = signal<boolean>(false);

  private readonly loadFooter = signal<boolean>(false);

  private readonly engineAlreadyExists = signal<boolean>(false);

  get headerDataInstant(): AuroraHeaderI | AuroraHeaderMobileI | undefined {
    return this.headerData();
  }

  get engineDataInstant(): AuroraEngineI | AuroraEngineModalI | undefined {
    return this.engineData();
  }

  get headInstant(): PageConfigHead | undefined {
    return this.pageHead();
  }

  get dynamicHeaderInstant(): boolean {
    return this.dynamicHeader();
  }

  get loadFooterInstant(): boolean {
    return this.loadFooter();
  }

  get engineAlreadyExistsInstant(): boolean {
    return this.engineAlreadyExists();
  }

  get footerInstant():
    | BodyComponent<AuroraStaticFooterI | AuroraStaticFooterV2I>
    | undefined {
    return this.footer();
  }

  constructor() {}

  renderer!: Renderer2;

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  private completeSpinner(): void {
    $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
    $('#preloader').addClass('loaded');
    $('.animate_hero').addClass('is-transitioned');
  }

  setLayoutElements<T>(response?: PageConfig<T | undefined>): void {
    if (response) {
      this.setHead(response.head);
      this.dynamicHeader.set(
        Utils.findComponent(response.body, 'topslider') !== undefined
      );
      this.loadFooter.set(true);
    }

    this.completeSpinner();

    this.initPinnedImages();
  }

  initPinnedImages(): void {
    const pinnedImages = document.querySelectorAll('.pinned-image');
    if (pinnedImages.length) {
      pinnedImages.forEach((pinnedImage) => {
        const container = pinnedImage.querySelector('.pinned-image__container');
        const image = container?.querySelector('img');
        const overlay = container?.querySelector(
          '.pinned-image__container-overlay'
        );
        const content = pinnedImage.querySelector('.pinned_over_content');
        const tl = gsap.timeline({ paused: true });
        tl.to(
          container,
          {
            scale: 1.05,
          },
          0
        );
        tl.from(
          content,
          {
            autoAlpha: 0,
          },
          0
        );
        tl.from(
          overlay,
          {
            autoAlpha: 0,
          },
          0
        );
        const trigger = ScrollTrigger.create({
          animation: tl,
          trigger: pinnedImage,
          start: 'top center',
          markers: false,
          pin: false,
          scrub: false,
        });
      });
    } else {
      setTimeout(() => {
        this.initPinnedImages();
      }, 100);
    }
  }

  setheaderData(
    headerData: AuroraHeaderI | AuroraHeaderMobileI | undefined
  ): void {
    this.headerData.set(headerData);
  }

  setHead(head: PageConfigHead | undefined): void {
    this.pageHead.set(head);
  }

  setLoadFooter(loadFooter: boolean): void {
    this.loadFooter.set(loadFooter);
  }

  setEngineAlreadyExists(engineAlreadyExists: boolean): void {
    this.engineAlreadyExists.set(engineAlreadyExists);
  }

  setFooter(
    footer:
      | BodyComponent<AuroraStaticFooterI | AuroraStaticFooterV2I>
      | undefined
  ): void {
    this.footer.set(footer);
  }

  setEngineData(
    engineData: AuroraEngineI | AuroraEngineModalI | undefined
  ): void {
    this.engineData.set(engineData);
  }
}
