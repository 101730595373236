export const TEMPLATES = {
  GENERAL: 'General Layout',
  CONTACT: 'Contact',
  GALLERY: 'Gallery',
  HOTEL: 'Hotel',
  HOTELS: 'Hotels',
  ROOMS: 'Rooms',
  ROOM_DETAIL: 'Room',
  DESTINATIONS: 'Destinations',
  DESTINATION_DETAIL: 'destination',
  PROMO_DETAIL: 'offer',
  LIST_PROMOS: 'Promos List',
  LIST_PROMOS_V2: 'Promos Grid',
  LOCATION: 'Location',
  LEGAL: 'Legal',
  EMPLOYMENT: 'Employment',
  WEBMAP: 'Webmap',
  THANKS: 'Thanks',
  FAQ: 'Faq',
  LOYALTY: 'loyalty',
  404: '404',
};
