import { HttpResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';
import { Utils } from '../../Utils/utils';
import { ApiService, GeneralConfigResponse } from '../api/api.service';
import { PageConfig } from '../interfaces/page-config.interface';
import {
  FavIcon,
  Language,
  PageFullUrls,
  WebConfig,
} from '../interfaces/site-config.interface';
import { TRANSLATIONS } from './translations';
@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  private readonly selectedLanguage = signal<Language | undefined>(undefined);
  private readonly webConfig = signal<WebConfig | undefined>(undefined);
  private readonly cdnImages = signal<string | undefined>(undefined);

  pages?: PageFullUrls[];

  currentPageId = 0;

  get selectedLanguageInstant(): Language | undefined {
    return this.selectedLanguage();
  }

  get webConfigInstant(): WebConfig | undefined {
    return this.webConfig();
  }

  get cdnImagesInstant(): string | undefined {
    return this.cdnImages();
  }

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly primengConfig: PrimeNGConfig
  ) {}

  changeLanguage(language: Language): void {
    this.selectedLanguage.set(language);
    this.primengConfig.setTranslation(TRANSLATIONS[language.locate]);
    localStorage.setItem('language', JSON.stringify(language));
  }

  setWebConfig(config: WebConfig): void {
    this.webConfig.set(config);
  }

  setCdnImages(cdn: string): void {
    this.cdnImages.set(`${cdn}/`);
  }

  setCurrenPageId(pageId: string | number): void {
    this.currentPageId = Number(pageId);
  }

  getGeneralConfig(
    isMocked = true
  ): Observable<HttpResponse<WebConfig> | WebConfig | undefined> {
    return this.apiService.getConfig().pipe(
      filter((data): data is GeneralConfigResponse => data !== null),
      tap((data) => {
        if (!localStorage.getItem('language')) {
          this.changeLanguage(
            (data.languages.find((lang) =>
              navigator.language.includes(lang.locate)
            ) as Language) ??
              data.languages.find((lang) => lang.locate === 'es')
          );
        } else {
          this.changeLanguage(
            JSON.parse(localStorage.getItem('language') ?? '')
          );
        }
        const langLocate = window.location.pathname.split('/')[1];
        if (langLocate !== this.selectedLanguageInstant?.locate && langLocate) {
          const language = data.languages.find(
            (lang) => lang.locate === langLocate
          );
          if (language) {
            this.changeLanguage(language);
          }
        }
        if (!this.cdnImagesInstant) {
          this.setCdnImages(data.cdn);
        }
      }),
      map((data) => {
        const dataCamel = Utils.convertObjectKeysToCamelCase(data);

        this.setCommonScripts(dataCamel);

        return {
          commonScripts: dataCamel.commonScripts,
          engine: dataCamel.engine,
          css: dataCamel.css,
          favIcons: dataCamel.favicons as FavIcon[],
          languages: dataCamel.languages,
          logos: dataCamel.logos,
          siteID: dataCamel.siteId,
          projectName: dataCamel.name,
          pages: dataCamel.pages as PageFullUrls[],
          chatBotBtn: {
            icon: 'icon-comment',
          },
          socialMedia: dataCamel.socialMedia,
        } as WebConfig;
      })
    );
  }

  getPageConfig<T>(
    pageId: string | number | undefined,
    itemId?: string | number
  ): Observable<HttpResponse<PageConfig<T>> | PageConfig<T> | undefined> {
    this.setCurrenPageId(pageId ?? 0);
    if (!pageId || !this.selectedLanguageInstant?.id) {
      this.router.navigateByUrl('404');
      return this.apiService.getPage(
        pageId,
        this.selectedLanguageInstant?.id!,
        itemId,
        location.href
      );
    }
    return this.apiService
      .getPage(pageId, this.selectedLanguageInstant?.id!, itemId, location.href)
      .pipe(
        filter((data) => data !== null),
        map((data) => {
          const dataCamel = Utils.convertObjectKeysToCamelCase(data);
          return dataCamel as PageConfig<T>;
        }),
        catchError((err) => throwError(() => new Error(err)))
      );
  }

  getPageName(id: number): string | undefined {
    return this.pages?.find((page) => page.pageId === id)?.name;
  }

  setCommonScripts(dataCamel: any) {
    this.pages = JSON.parse(JSON.stringify(dataCamel.pages));

    this.pages?.forEach((page: PageFullUrls) => {
      if (page.route) {
        page.route = page.route?.map((route) => {
          if (route.url) {
            route.url = route.url.slice(1, -1);
            return route;
          } else {
            return route;
          }
        });
      }
    });

    dataCamel.scripts?.forEach((script: any) => {
      document.head.innerHTML += script;
    });

    
    if (dataCamel.commonScripts?.GTM?.[0]) {
      const gtm = document.createElement('script');
      document.head.appendChild(
          document.createComment('Google Tag Manager')
      );
      gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', 'GTM-${dataCamel.commonScripts?.GTM?.[0]}');`;
      document.head.appendChild(gtm);
      document.head.appendChild(
          document.createComment('End Google Tag Manager')
      );
      document.body.appendChild(
          document.createComment('Google Tag Manager (noscript)')
      );
      const gtmNoScript = document.createElement('noscript');
      gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${dataCamel.commonScripts?.GTM?.[0]}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(gtmNoScript);
      document.body.appendChild(
          document.createComment('End Google Tag Manager (noscript)')
      );
    }
  }
}
