import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  Aurora404I,
  AuroraAdvantagesI,
  AuroraAdvantagesItem,
  AuroraAdvantagesSmallI,
  AuroraBannerfotoI,
  AuroraBannervideoI,
  AuroraBreadcrumbI,
  AuroraButtonI,
  AuroraCards3I,
  AuroraCards3Item,
  AuroraContactFormI,
  AuroraContactMapaI,
  AuroraContactMapItem,
  AuroraDropdownI,
  AuroraEngineI,
  AuroraEngineModalI,
  AuroraEquipamentI,
  AuroraEquipmentItem,
  AuroraFaqItem,
  AuroraFaqsI,
  AuroraGaleriaI,
  AuroraGalleryFilter,
  AuroraGalleryItem,
  AuroraGrid3colI,
  AuroraGrid3ColItem,
  AuroraHeaderI,
  AuroraInfo2imgI,
  AuroraInfoImgBigI,
  AuroraInfoSliderI,
  AuroraInputI,
  AuroraLanguageModalI,
  AuroraLegalI,
  AuroraMenuI,
  AuroraNewsletterI,
  AuroraOfferDetailI,
  AuroraOffersListGridI,
  AuroraOffersListGridItem,
  AuroraOffersListI,
  AuroraOffersListItem,
  AuroraRoomsListEquipment,
  AuroraRoomsListI,
  AuroraRoomsListItemV2,
  AuroraSlider3colI,
  AuroraSlider3ColItem,
  AuroraSliderverticalI,
  AuroraSostenibilidadI,
  AuroraStaticFooterI,
  AuroraStaticFooterV2I,
  AuroraThankI,
  AuroraTopSliderI,
  AuroraTopSliderItem,
  AuroraWebmapI,
  AuroraZigzagI,
  AuroraZigzagItem,
  DropdownOption,
  ScreenSizerService,
} from 'ctt-aurora';
import { AuroraImageI } from 'ctt-aurora/lib/interfaces/aurora-image.interface';
import { Utils } from '../../Utils/utils';
import { ConsultTypeI } from '../interfaces/consult-type.interface';
import { BodyComponent } from '../interfaces/page-config.interface';
import { SiteConfigService } from '../site-config/site-config.service';

@Injectable({
  providedIn: 'root',
})
export class MapperService {
  get isMinFullTabletInstant(): boolean {
    return this.screenSizer.minFullTabletInstant();
  }

  floatingButtons?: AuroraButtonI[];

  constructor(
    private readonly siteSvc: SiteConfigService,
    private readonly screenSizer: ScreenSizerService
  ) {}

  getImageResponsive(
    image: ImageResponse | undefined,
    isFigure = true
  ): string | AuroraImageI | undefined {
    let selectedImage;
    if (this.screenSizer.mobile()) {
        selectedImage = image?.thumbnails?.[375]?.webp;
    } else if (this.screenSizer.isSmallTabletInstant()) {
        selectedImage = image?.thumbnails?.[540]?.webp;
    } else if (this.screenSizer.isMediumTabletInstant()) {
        selectedImage = image?.thumbnails?.[768]?.webp;
    } else if (this.screenSizer.isFullTabletInstant()) {
        selectedImage = image?.thumbnails?.[1024]?.webp;
    } else if (this.screenSizer.isSmallDesktopInstant()) {
        selectedImage = image?.thumbnails?.[1280]?.webp;
    } else if (this.screenSizer.minMediumDesktop()) {
        selectedImage = image?.path?.webp;
    } else {
        selectedImage = image?.thumbnails?.[1366]?.webp;

    }
    return selectedImage
      ? isFigure
        ? `${this.siteSvc.cdnImagesInstant}${selectedImage}`
        : {
            src: `${this.siteSvc.cdnImagesInstant}${selectedImage}`,
            alt: image?.alt,
          }
      : undefined;
  }

  getArrayImageResponsive(
    images: ImageResponse[] | undefined,
    isFigure = true
  ): (string | AuroraImageI)[] | undefined {
    return images?.map((img) => this.getImageResponsive(img, isFigure)!);
  }

  private generateAgesOptions(
    maxLength: number,
    minLength = 0
  ): DropdownOption[] {
    const options: DropdownOption[] = [];
    for (let i = minLength; i <= maxLength; i++) {
      options.push({ code: i, name: `${i}` });
    }
    return options;
  }

  mapComponents<T>(body: any[], addons?: ComponentsAddons): BodyComponent<T>[] {
    const bodyMapped: BodyComponent<T>[] = body.map((component) => {
      const componentMapped: BodyComponent<T> = {
        name: component.name?.toLowerCase(),
        order: component.order,
        props: {},
      } as BodyComponent<T>;

      try {
        if(component.props) {
          switch (component.name?.toLowerCase()) {
            case 'footerstatic':
              componentMapped.props = this.mapStaticFooter(component.props) as T;
              break;
            case 'footerstaticv2':
              componentMapped.props = this.mapStaticFooterV2(component.props) as T;
              break;
            case 'languagesmodal':
              componentMapped.props = this.mapLanguageModal(component.props) as T;
              break;
            case 'newslettermodal':
              componentMapped.props = this.mapNewsletterModal(component.props) as T;
              break;
            case 'topslider':
              componentMapped.props = this.mapTopSlider(component.props) as T;
              break;
            case 'contactform':
              componentMapped.props = this.mapContactForm(
                component.props,
                addons?.contactOffice,
                addons?.consultType
              ) as T;
              break;
            case 'contactmap':
              componentMapped.props = this.mapContactMap(component.props) as T;
              break;
            case 'webmap':
              componentMapped.props = this.mapWebmap(component.props) as T;
              break;
            case 'faq':
              componentMapped.props = this.mapFaq(component.props) as T;
              break;
            case 'thanks':
              componentMapped.props = this.mapThanks(component.props) as T;
              break;
            case 'legal':
              componentMapped.props = this.mapLegal(component.props) as T;
              break;
            case 'sliderverticalinforight':
              componentMapped.props = this.mapSliderVertical(component.props) as T;
              break;
            case 'sliderverticalinfoleft':
              componentMapped.props = this.mapSliderVertical(component.props) as T;
              break;
            case 'advantages':
              componentMapped.props = this.mapAdvantages(component.props) as T;
              break;
            case 'advantagessmall':
              componentMapped.props = this.mapAdvantagesSmall(component.props) as T;
              break;
            case 'info2imgleft':
              componentMapped.props = this.mapInfo2img(component.props) as T;
              break;
            case 'info2imgright':
              componentMapped.props = this.mapInfo2img(component.props) as T;
              break;
            case 'infosliderinfoleft':
              componentMapped.props = this.mapInfoSlider(component.props) as T;
              break;
            case 'infosliderinforight':
              componentMapped.props = this.mapInfoSlider(component.props) as T;
              break;
            case 'slider3col':
              componentMapped.props = this.mapSlider3col(component.props) as T;
              break;
            case 'offerdetail':
              componentMapped.props = this.mapOfferDetail(component.props) as T;
              break;
            case 'offerlist':
              componentMapped.props = this.mapOffersList(component.props) as T;
              break;
            case 'offerlistgrid':
              componentMapped.props = this.mapOffersListGrid(component.props) as T;
              break;
            case 'zigzag':
              componentMapped.props = this.mapZigzag(component.props) as T;
              break;
            case 'cards3':
              componentMapped.props = this.mapCards3(component.props) as T;
              break;
            case 'infoimgbig':
              componentMapped.props = this.mapInfoImgBig(component.props) as T;
              break;
            case 'bannervideo':
              componentMapped.props = this.mapBannerVideo(component.props) as T;
              break;
            case 'bannerphoto':
              componentMapped.props = this.mapBannerFoto(component.props) as T;
              break;
            case 'roomlistinfo':
              componentMapped.props = this.mapRoomsList(component.props) as T;
              break;
            case 'roomlistinfov2':
              componentMapped.props = this.mapRoomsList(component.props) as T;
              break;
            case 'module404':
              componentMapped.props = this.map404(component.props) as T;
              break;
            case 'grid3col':
              componentMapped.props = this.mapGrid3col(component.props) as T;
              break;
            case 'sustainability':
              componentMapped.props = this.mapSustainability(component.props) as T;
              break;
            case 'gallery':
              componentMapped.props = this.mapGallery(component.props) as T;
              break;
            case 'equipment':
              componentMapped.props = this.mapEquipment(component.props) as T;
              break;
            case 'nav':
              componentMapped.props = this.mapMenu(
                component.props,
                addons?.menuSocialMedia
              ) as T;
              break;
    
            default:
              break;
          }
        }
      } catch (error) {
        console.log(error)
      }

      return componentMapped;
    });

    return bodyMapped;
  }

  mapHeader(props: any | undefined, menu: AuroraMenuI): AuroraHeaderI {
    const link = props?.navLinks?.[0]?.linkValue;
    const headerButtons = props?.headerButtons?.props;
    this.floatingButtons = [
      {
        icon: `icon-${headerButtons.texts?.transferIcon}`,
        label: headerButtons?.texts?.transfer,
        url: headerButtons?.buttons?.find((item: any) => item.label.toLowerCase() === headerButtons?.texts?.transfer.toLowerCase().trim())?.linkValue || ''
      },
      {
        icon: `icon-${headerButtons.texts?.checkinIcon}`,
        label: headerButtons?.texts?.checkin,
        url: headerButtons?.buttons?.find((item: any) => item.label.toLowerCase() === headerButtons?.texts?.checkin?.toLowerCase().trim())?.linkValue || ''
      }
    ];
    
    return {
      lang: this.siteSvc.selectedLanguageInstant?.locate.toUpperCase(),
      btnBook: { label: headerButtons?.texts?.bookNow },
      linkLogo: {
        url: link?.charAt(link.length - 1) === '/' ? link?.slice(0, -1) : link,
      },
      menu,
    } as AuroraHeaderI;
  }

  mapBreadcrumbs(props: any | undefined): AuroraBreadcrumbI {
    return {
      links: (props?.items as any[])
        ?.sort((a, b) => a - b)
        ?.map(
          (item) =>
            ({
              label: item?.label,
              linkType: 'internal',
              url: item?.url
                ? item?.url?.charAt(item?.url?.length - 1) === '/'
                  ? item?.url?.slice(0, -1)
                  : item?.url
                : undefined,
            } as AuroraButtonI)
        ),
    } as AuroraBreadcrumbI;
  }

  //TODO mapear resto de propiedades cuando vengan del back
  mapMenu(
    props: any | undefined,
    socialMedia: any | undefined,
    address?: any | undefined
  ): AuroraMenuI {
    const links = Utils.mapButtons(
      props?.nav?.map((link: any) => ({
        ...link,
        label: link.translations?.[0]?.name,
      }))
    );
    return {
      links: links.slice(0, 6),
      bottomLinks: links.slice(6),
      address: {
        address: props?.address?.[0]?.address,
        city: props?.address?.[0]?.city,
        country: props?.address?.[0]?.country,
        cp: props?.address?.[0]?.cp,
        title: props?.address?.[0]?.name,
        province: props?.address?.[0]?.province,
        links: props?.links?.address?.[0]?.map((link: AuroraButtonI) => ({
          ...link,
          linkValue: link.linkValue + '#location' 
        })),
      },
      contact: {
        links: props?.links?.contact?.[0],
        text: props?.texts,
      },
      socialMedia: socialMedia?.map(
        (item: any) =>
          ({
            icon: `aurora-${item.name?.toLowerCase()}`,
            url: item.url,
          } as AuroraButtonI)
      ),
      img: this.getImageResponsive(props?.multimedia?.imagen?.[0], false),
      imgBackground: this.getImageResponsive(
        props?.multimedia?.background?.[0],
        false
      ),
    } as AuroraMenuI;
  }

  mapTopSlider(props: any | undefined): AuroraTopSliderI | undefined {
    return props
      ? ({
          buttons: this.floatingButtons,
          items: props?.multimedia?.[
            props?.multimedia?.imagenes ? 'imagenes' : 'multimedia'
          ]?.map(
            (item: ImageResponse) =>
              ({
                title: item.title,
                subtitle: item.pretitle,
                img: this.getImageResponsive(item),
              } as AuroraTopSliderItem)
          ),
          video: {
            src: props?.video?.src,
            title: props?.video?.title,
          }
        } as AuroraTopSliderI)
      : undefined;
  }

  mapSustainability(props: any | undefined): AuroraSostenibilidadI {
    return {
      title: props?.texts?.title,
      subtitle: props?.texts?.subtitle,
      img: this.getImageResponsive(props?.multimedia?.imagen[0]),
      titleBox: props?.texts?.titleBox,
      description: props?.texts?.description,
      button: Utils.mapButtons(props?.buttons)[0],
    } as AuroraSostenibilidadI;
  }

  mapGrid3col(props: any | undefined): AuroraGrid3colI {
    return {
      buttons: Utils.mapButtons(props?.buttons),
      title: props?.texts?.title,
      subtitle: props?.texts?.subtitle,
      items: props?.multimedia?.imagenes?.map(
        (item: ImageResponse) =>
          ({
            title: item.title,
            description: item.description,
            img: this.getImageResponsive(item, false),
            button: Utils.mapButtons(item.buttons)[0],
          } as AuroraGrid3ColItem)
      ),
    } as AuroraGrid3colI;
  }

  mapFaq(props: any | undefined): AuroraFaqsI {
    return {
      button: Utils.mapButtons(props?.buttons)[0],
      description: props?.texts?.description,
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      items: props?.items?.map(
        (item: any) =>
          ({
            label: item.title,
            description: item.text,
          } as AuroraFaqItem)
      ),
    } as AuroraFaqsI;
  }

  /**
   * TODO
   * Mapear items
   */
  mapEquipment(props: any | undefined): AuroraEquipamentI {
    let items;
    
    if (props.Ventajas) {
      items = Array.isArray(props?.Ventajas)
        ? props?.Ventajas
        : Object?.values(props?.Ventajas);

      items = items?.map(
        (item: any) =>
          ({
            icon: 'icon-' + item.icon,
            label: item.title,
          } as AuroraEquipmentItem)
      );
    } else if (props.services) {
      items = Array.isArray(props?.services)
        ? props?.services
        : Object?.values(props?.services);

      items = items?.map(
        (item: any) =>
          ({
            icon: 'icon-' + item.class,
            label: item.name,
          } as AuroraEquipmentItem)
      );
    } else if (props.equipment) {
      items = Array.isArray(props?.equipment)
        ? props?.equipment
        : Object?.values(props?.equipment);

      items = items?.map(
        (item: any) =>
          ({
            icon: 'icon-' + item.class,
            label: item.name,
          } as AuroraEquipmentItem)
      );
    }
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      description: props?.texts?.description,
      button: Utils.mapButtons(props?.buttons)[0],
      seeMore: props?.texts?.verMas,
      seeLess: props?.texts?.verMenos,
      items,
    } as AuroraEquipamentI;
  }

  /**
   * TODO
   * Mapear los filtros y equipamiento
   */
  mapRoomsList(props: any | undefined): AuroraRoomsListI {
    return {
      filters: props?.filters?.map(
        (filter: any) => ({
          placeholder: filter.name,
          options: filter?.values?.map((option: string) => ({
            code: option,
            name: option
          })),
          title: filter.name,
          smalltitle: filter.smalltitle,
          filterId: filter.filterId,
        })
      ) as AuroraDropdownI[],
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      items: props?.rooms.map(
        (item: any) => 
          ({
            id: item.id,
            bedroomFilterId: item.texts?.beds,
            characteristicsFilterId: item.texts?.location,
            link: Utils.mapButtons(item.links)[0],
            button: Utils.mapButtons(item.buttons)[0],
            title: item.texts?.name,
            description: item.texts?.description,
            pretitle: item.texts?.additional1,
            ticker: item.hotelRoomEngine?.ticker,
            engineUrl: Utils.changeLanguageInURL(item.hotelRoomEngine.url, this.siteSvc.selectedLanguageInstant?.locate),
            equipment: item?.equipment?.map(
              (item: any) =>
                ({
                  icon: 'icon-' + item.class,
                  label: item.name,
                } as AuroraRoomsListEquipment)
            ),
            img: this.getImageResponsive(
              item.multimedia?.find((img: ImageResponse) => img.cover) ??
                item.multimedia?.[0],
              false
            ),
            images: this.getArrayImageResponsive(item.multimedia, false),
          } as AuroraRoomsListItemV2),
      ),
    } as AuroraRoomsListI;
  }

  mapLanguageModal(props: any | undefined): AuroraLanguageModalI {
    return {
      title: props?.texts?.title,
      subtitle: props?.texts?.subtitle,
      items: props?.languages,
      selected: props.languages.find(
        (lng: any) => lng.id === this.siteSvc.selectedLanguageInstant?.id
      ),
    } as AuroraLanguageModalI;
  }

  mapStaticFooter(props: any | undefined): AuroraStaticFooterI {
    return {
      title: props?.texts?.contactTitle,
      ndTitle: props?.texts?.ndTitle,
      rdTitle: props?.texts?.rdTitle,
      address: props?.texts?.addressDrescription,
      copyright: props?.texts?.copyright,
      img: '',
      legalAdvise: '',
      contactLinks: [],
      links: Utils.mapButtons(props?.links),
      socialMedia: props?.socialMedia?.map(
        (link: any) =>
          ({
            icon: `aurora-${link.name.toLowerCase()}`,
            url: link.url,
          } as AuroraButtonI)
      ),
      input: {},
    } as AuroraStaticFooterI;
  }

  mapStaticFooterV2(props: any | undefined): AuroraStaticFooterV2I {
    const addressBtn = Utils.mapButtons(props?.links?.address?.[0])?.[0];
    return {
      title: props?.texts?.addressTitle,
      ndTitle: props?.texts?.contactTitle,
      rdTitle: props?.texts?.socialTitle,
      fourthTitle: props?.texts?.fourthTitle,
      address: props?.texts?.addressDescription,
      copyright: props?.texts?.copyright,
      imgs: props?.multimedia?.certificados.map(
        (cert: any) => this.getImageResponsive(cert, false)
      ), 
      contactLinks: Utils.mapButtons(props?.links?.contact[0]),
      links: Utils.mapButtons(props?.links?.footerLinks?.[0]),
      socialMedia: props?.socialMedia?.map(
        (link: any) =>
          ({
            icon: `aurora-${link.name.toLowerCase()}`,
            url: link.url,
          } as AuroraButtonI)
      ),
      buttons: [{ ...addressBtn, url: addressBtn?.url + '#location' }],
    } as AuroraStaticFooterV2I;
  }

  mapEngine(props: any | undefined): AuroraEngineI | AuroraEngineModalI {
    return {
      button: { label: props?.texts?.motorSubmit, url: props?.url },
      datepickerData: {
        placeholder: props?.texts?.motorDateTitle,
      },
      guestsInput: {
        placeholder: props?.texts?.motorGuestsInputHolder,
      },
      promoInput: {
        placeholder: props?.texts?.motorPromoHolder,
      },
      guests: {
        btn: { label: props?.texts?.motorApply },
        adultsDropdown: {
          title: props?.texts?.motorAdultsTitle,
          options: this.generateAgesOptions(props?.maxAdults, 1),
        },
        kidsDropdown: {
          title: props?.texts?.motorKidsTitle,
          smallTitle: props?.texts?.motorKidsSmallLabel,
          options: this.generateAgesOptions(props?.maxChildren),
        },
        babiesDropdown: {
          title: props?.texts?.motorBabiesTitle,
          smallTitle: props?.texts?.motorBabiesSmallTitle,
          options: this.generateAgesOptions(props?.maxBabies),
        },
        agesDropdown: {
          options: this.generateAgesOptions(
            props?.maxAgeChildren,
            props?.maxAgeBabies + 1
          ),
        },
        agesLabel: props?.texts?.motorAgesTitle,
        kidsLabel: props?.texts?.motorKidLabel,
      },
      title: props?.texts?.motorTitle,
      subtitle: props?.texts?.motorSubtitle,
      url: props?.url,
      hotel: props?.hotels?.[0],
      codigo: props?.codigo ? props?.codigo : 'www.seaclub.com', //to change, dont come from back
      glValue: props?.glValue,
      defaultButton: props?.defaultInternalButton,
    } as AuroraEngineModalI | AuroraEngineI;
  }

  mapNewsletterModal(props: any | undefined): AuroraNewsletterI {
    return {
      conditions: props?.texts?.conditions,
      title: props?.texts?.title,
      subtitle: props?.texts?.subtitle,
      button: Utils.mapButtons(props?.buttons)[0],
      image: this.getImageResponsive(props?.multimedia?.defaultimage?.[0]),
      input: {
        name: 'mail',
        placeholder: props?.texts?.inputHolder,
        valid: true,
        value: '',
        error: props?.texts?.newsErrorEmail,
      } as AuroraInputI,
      img: this.getImageResponsive(props?.multimedia?.image?.[0]),
    } as AuroraNewsletterI;
  }

  mapContactForm(
    props: any | undefined,
    ndProps: any | undefined,
    ndPropsConsult: any | undefined
  ): AuroraContactFormI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.description,
      button: Utils.mapButtons(props?.buttons)[0],
      textarea: {
        title: props?.texts?.inputMessage,
        placeholder: props?.texts?.inputMessagePlaceHolder,
        value: '',
      },
      conditions: props?.texts?.conditions,
      dropdown: {
        title: props?.texts?.dropdownTitle,
        options: ndPropsConsult?.map(
          (prop: any) => ({ code: prop.id, name: prop.name } as DropdownOption)
        ),
      },
      inputs: [
        {
          title: props?.texts?.inputName,
          name: Utils.normalizeText(props?.texts?.inputName),
          placeholder: 'pepe',
          value: '',
          type: 'text',
          error: props?.texts?.errorRequired,
          validators: [Validators.required],
          valid: true,
        },
        {
          title: props?.texts?.inputSurnames,
          name: Utils.normalizeText(props?.texts?.inputSurnames),
          placeholder: props?.texts?.inputSurnamesPlaceHolder,
          value: '',
          type: 'text',
          error: props?.texts?.errorRequired,
          validators: [Validators.required],
          valid: true,
        },
        {
          title: props?.texts?.inputEmail,
          name: Utils.normalizeText(props?.texts?.inputEmail),
          placeholder: props?.texts?.inputEmailPlaceHolder,
          value: '',
          type: 'text',
          error: props?.texts?.errorEmail,
          validators: [Validators.email, Validators.required],
          valid: true,
        },
        {
          title: props?.texts?.inputPhone,
          name: Utils.normalizeText(props?.texts?.inputPhone),
          placeholder: props?.texts?.inputPhonePlaceHolder,
          value: '',
          type: 'text',
          error: props?.texts?.errorRquired,
          validators: [Validators.required],
          valid: true,
        },
      ] as AuroraInputI[],
      info: {
        address: {
          icon: 'icon-' + ndProps?.texts?.addressIcon,
          title: ndProps?.texts?.addressTitle,
          text: ndProps?.links?.address?.[0]?.[0]?.label,
        },
        email: {
          icon: 'icon-' + ndProps?.texts?.emailIcon,
          title: ndProps?.texts?.emailTitle,
          links: Utils.mapButtons(ndProps?.links?.email?.[0]).map(
            (btn) => ({ ...btn, url: `mailto:${btn?.url}l` } as AuroraButtonI)
          ),
        },
        phone: {
          icon: 'icon-' + ndProps?.texts?.phoneIcon,
          title: ndProps?.texts?.phoneTitle,
          links: Utils.mapButtons(ndProps?.links?.phone?.[0]).map(
            (btn) => ({ ...btn, url: `phone:${btn?.url}l` } as AuroraButtonI)
          ),
        },
      },
    } as AuroraContactFormI;
  }

  mapContactMap(props: any | undefined): AuroraContactMapaI | undefined {
    return {
      contentUrl: props?.texts?.phoneIcon,
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      mapUrl: props?.texts?.mapUrl,
      titleMap: props?.texts?.seeMap,
      items: props?.Ventajas?.map(
        (item: any) =>
          ({
            title: item.title,
            description: item.text,
            icon: `icon-${item.icon}`,
          } as AuroraContactMapItem)
      ),
    } as AuroraContactMapaI;
  }

  mapSliderVertical(props: any | undefined): AuroraSliderverticalI | undefined {
    return {
      button: Utils.mapButtons(props?.buttons)[0],
      description: props?.texts.description,
      pretitle: props?.texts.subtitle2,
      subtitle: props?.texts.subtitle,
      title: props?.texts.title,
      img: props?.multimedia?.imagenes?.map((img: ImageResponse) =>
        this.getImageResponsive(img)
      ),
      items: [],
    } as AuroraSliderverticalI;
  }

  mapAdvantages(props: any | undefined): AuroraAdvantagesI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      img: this.getImageResponsive(props?.multimedia?.background?.[0], false),
      items: props?.Ventajas?.map(
        (item: any) =>
          ({
            title: item.title,
            icon: `icon-${item.icon}`,
            description: item.text,
          } as AuroraAdvantagesItem)
      ),
    } as AuroraAdvantagesI;
  }

  mapAdvantagesSmall(
    props: any | undefined
  ): AuroraAdvantagesSmallI | undefined {
    return {
      items: props?.Ventajas?.map(
        (item: any) =>
          ({
            title: item.title,
            icon: `icon-${item.icon}`,
            description: item.text,
          } as AuroraAdvantagesItem)
      ),
    } as AuroraAdvantagesSmallI;
  }

  mapGallery(props: any | undefined): AuroraGaleriaI | undefined {
    const items: AuroraGalleryItem[] = [];
    let id = 0;
    const filters = props?.filters?.map((filter: any) => {
      id++;
      return {
        id,
        room: filter.room,
        rooms: filter.rooms,
        text: filter.name,
        all: filter.all,
      } as AuroraGalleryFilter;
    });

    for (let i = 0; i < filters.length; i++) {
      props?.filters?.[i]?.multimedia?.forEach((img: ImageResponse) => {
        items.push({
          category: filters[i].id,
          img: this.getImageResponsive(img, false),
          title: (img as any).tags?.[0],
          order: (img as any).order,
        } as AuroraGalleryItem);
      });
    }
    items.sort((a, b) => (a as any).order - (b as any).order);

    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle, 
      filterTitle: props?.texts?.modalTitleCategories,
      filterSubtitle: props?.texts?.modalSubtitleCategories,
      roomTitle: props?.texts?.modalTitleRooms,
      roomSubtitle: props?.texts?.modalSubtitleRooms,
      items,
      filters,
      filterButtons: [
        {
          label: props?.texts?.filterCategories,
        },
        {
          label: props?.texts?.filterRooms,
        },
      ],
      links: [
        { label: props?.texts?.verMas },
        { label: props?.texts?.verMenos },
      ],
    } as AuroraGaleriaI;
  }

  mapInfo2img(props: any | undefined): AuroraInfo2imgI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      subtitle: props?.texts?.subtitle,
      description: props?.texts?.description,
      img: this.getImageResponsive(props?.multimedia?.imagen?.[0], false),
      smallImg: this.getImageResponsive(
        props?.multimedia?.imagensmall?.[0],
        false
      ),
      links: Utils.mapButtons(props?.links),
      buttons: Utils.mapButtons(props?.buttons),
    } as AuroraInfo2imgI;
  }

  mapInfoSlider(props: any | undefined): AuroraInfoSliderI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      description: props?.texts?.description,
      link: Utils.mapButtons(props?.links)[0],
      button: Utils.mapButtons(props?.buttons)[0],
      images: this.getArrayImageResponsive(props?.multimedia?.imagenes, false),
    } as AuroraInfoSliderI;
  }

  mapSlider3col(props: any | undefined): AuroraSlider3colI | undefined {
    let items;
    let title = props?.texts?.title;
    let pretitle = props?.texts?.subtitle;
    if (props.multimedia) {
      items = props?.multimedia?.imagenes?.map(
        (item: ImageResponse) =>
          ({
            title: item.title,
            pretitle: item.pretitle,
            img: this.getImageResponsive(item),
            link: Utils.mapButtons(item.links)[0],
          } as AuroraSlider3ColItem)
      );
    } else if (props?.room) {
      items = props?.room?.multimedia.map(
        (item: ImageResponse) => 
        ({
          img: this.getImageResponsive(item)
        })
      ),
      title = props?.texts?.title;
      pretitle = props?.texts?.subtitle;
    }
    return {
      title,
      pretitle,
      items,
    } as AuroraSlider3colI;
  }

  mapBannerFoto(props: any | undefined): AuroraBannerfotoI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      description: props?.texts?.description,
      img: this.getImageResponsive(props?.multimedia?.imagen?.[0], false),
      button: Utils.mapButtons(props?.buttons)[0],
    } as AuroraBannerfotoI;
  }

  /**
   * TODO
   * Mapear el video cuando venga en formato array
   */

  mapBannerVideo(props: any | undefined): AuroraBannervideoI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      video: [{ src: props.video.src, type: 'video/mp4' }],
      videoTitle: props?.video.title,
      button: Utils.mapButtons(props?.buttons)[0],
    } as AuroraBannervideoI;
  }

  mapOfferDetail(props: any | undefined): AuroraOfferDetailI | undefined {
    const offer = props?.offer?.[0];
    return {
      subtitle: offer?.texts?.subtitle,
      title: offer?.texts?.title,
      ndTitle: offer?.texts?.ndTitle,
      description: offer?.texts?.description,
      ndDescription: offer?.texts?.conditions,
      button: Utils.mapButtons(offer?.buttons)[0],
      link: Utils.mapButtons(offer?.links)[0],
      currency: offer.discount ? '%' : offer.currency,
      price: offer.price ?? offer.discount,
      labelFrom: offer?.texts?.additional2,
      img: this.getImageResponsive(
        offer?.multimedia?.find((img: ImageResponse) => img.cover),
        false
      ),
    } as AuroraOfferDetailI;
  }

  mapOffersList(props: any | undefined): AuroraOffersListI | undefined {
    return {
      pretitle: props?.texts.pretitle,
      title: props?.texts.title,
      description: props?.texts?.description,
      button: Utils.mapButtons(props?.links)[0],
      items: props?.offers?.map(
        (offer: any) =>
          ({
            id: offer.id,
            currency: offer.discount ? '%' : offer.currency,
            title: offer.texts?.title,
            pretitle: offer.texts?.additional1,
            labelFrom: offer.texts?.additional2,
            offerUrl: offer.links?.[0].linkValue,
            img: this.getArrayImageResponsive(offer.multimedia, false),
            price: offer.price ?? offer.discount,
          } as AuroraOffersListItem)
      ),
    } as AuroraOffersListI;
  }

  mapOffersListGrid(props: any | undefined): AuroraOffersListGridI | undefined {
    return {
      pretitle: props?.texts.titleDark,
      title: props?.texts.title,
      description: props?.texts?.description,
      button: Utils.mapButtons(props?.buttons),
      items: props?.offers
        ?.filter(
          (item: any) =>
            new Date() >= new Date(item.visibleFrom) &&
            new Date() <= new Date(item.visibleTo)
        )
        ?.map(
          (offer: any) =>
            ({
              id: offer.id,
              currency: offer.discount ? '%' : offer.currency,
              title: offer.texts?.title,
              pretitle: offer.texts?.additional1,
              smallPretitle: offer.texts?.additional3,
              labelFrom: offer.texts?.additional2,
              link: Utils.mapButtons(offer.links)[0],
              img: this.getImageResponsive(
                offer.multimedia.find((img: ImageResponse) => img.cover),
                false
              ),
              price: offer.price ?? offer.discount,
            } as AuroraOffersListGridItem)
        ),
    } as AuroraOffersListGridI;
  }

  mapZigzag(props: any | undefined): AuroraZigzagI | undefined {
    return {
      items: props?.multimedia?.imagenes?.map(
        (item: ImageResponse) =>
          ({
            title: item.title,
            pretitle: item.pretitle,
            description: item.description,
            img: this.getImageResponsive(item, false),
            button: Utils.mapButtons(item.links)[0],
          } as AuroraZigzagItem)
      ),
    } as AuroraZigzagI;
  }

  mapCards3(props: any | undefined): AuroraCards3I | undefined {
    let items;

    if (props?.multimedia?.imagenes) {
      items = props?.multimedia?.imagenes?.map(
        (item: ImageResponse) =>
          ({
            title: item.title,
            pretitle: item.pretitle,
            img: this.getImageResponsive(item),
            link: Utils.mapButtons(item.links)[0],
          } as AuroraCards3Item)
      );
    } else if (props.rooms) {
      items = props?.rooms?.map(
        (item: any) =>
          ({
            title: item?.texts?.name,
            pretitle: item.texts?.description,
            img: this.getImageResponsive(
              (item?.multimedia as ImageResponse[])?.find((img) => img.cover)
            ),
            link: Utils.mapButtons(item.links)[0],
          } as AuroraCards3Item)
      );
    }

    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      button: Utils.mapButtons(props?.buttons)[0],
      items,
    } as AuroraCards3I;
  }

  mapInfoImgBig(props: any | undefined): AuroraInfoImgBigI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      button: Utils.mapButtons(props?.buttons)[0],
      description: props?.texts?.description,
      img: this.getImageResponsive(props?.multimedia?.imagen?.[0]),
      smallImg: this.getImageResponsive(props?.multimedia?.imagensmall?.[0]),
    } as AuroraInfoImgBigI;
  }

  // mapEmploymentForm(props: any | undefined): EmploymentFormI | undefined {
  //   return {
  //     title: UtilsTent.validateText(props?.texts?.title),
  //     button: {
  //       label: UtilsTent.validateText(props?.texts?.button),
  //     } as ButtonI,
  //     conditionsLink: UtilsTent.validateText(props?.texts?.conditions),
  //     requiredLabel: UtilsTent.validateText(props?.texts?.required),
  //     inputs: [
  //       {
  //         name: 'name',
  //         title: UtilsTent.validateText(props?.texts?.name),
  //         placeholder: UtilsTent.validateText(props?.texts?.namePlaceholder),
  //         value: '',
  //         error: props?.texts?.errorRequired,
  //         type: 'text',
  //         icon: 'icon-user',
  //         valid: true,
  //       },
  //       {
  //         name: 'surnames',
  //         title: UtilsTent.validateText(props?.texts?.surnames),
  //         placeholder: UtilsTent.validateText(
  //           props?.texts?.surnamesPlaceholder
  //         ),
  //         value: '',
  //         error: props?.texts?.errorRequired,
  //         type: 'text',
  //         icon: 'icon-user',
  //         valid: true,
  //       },
  //       {
  //         name: 'phone',
  //         title: UtilsTent.validateText(props?.texts?.phone),
  //         placeholder: UtilsTent.validateText(props?.texts?.phonePlaceholder),
  //         value: '',
  //         error: props?.texts?.errorRequired,
  //         type: 'tel',
  //         icon: 'icon-phone',
  //         pattern: '[0-9+]*',
  //         valid: true,
  //       },
  //       {
  //         name: 'email',
  //         title: UtilsTent.validateText(props?.texts?.email),
  //         placeholder: UtilsTent.validateText(props?.texts?.emailPlaceholder),
  //         value: '',
  //         error: props?.texts?.errorEmail,
  //         type: 'email',
  //         icon: 'icon-envelope',
  //         valid: true,
  //       },
  //       {
  //         name: 'cv',
  //         title: UtilsTent.validateText(props?.texts?.cv),
  //         placeholder: UtilsTent.validateText(props?.texts?.cvPlaceholder),
  //         value: '',
  //         error: props?.texts?.errorRequired,
  //         type: 'file',
  //         icon: 'icon-cv',
  //         valid: true,
  //       },
  //     ] as InputI[],
  //   } as EmploymentFormI;
  // }

  mapWebmap(props: any | undefined): AuroraWebmapI | undefined {
    return {
      links: (
        props?.pages?.map(
          (link: any) =>
            ({
              label: link.name,
              url: link.url,
              linkType: 'internal',
            } as AuroraButtonI)
        ) as AuroraButtonI[]
      ).filter(
        (link) =>
          link.url?.split('/')[1] ===
          this.siteSvc.selectedLanguageInstant?.locate
      ),
      title: props?.texts?.title,
      pretitle: props?.texts?.pretitle,
      image: this.getImageResponsive(props?.multimedia?.image?.[0]) as string,
    };
  }

  mapThanks(props: any | undefined): AuroraThankI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      description: props?.texts?.description,
      img: {
        src: this.getImageResponsive(props?.multimedia?.image?.[0]),
        alt: '',
      },
      button: Utils.mapButtons(props?.buttons)[0],
    } as AuroraThankI;
  }

  mapLegal(props: any | undefined): AuroraLegalI | undefined {
    return {
      title: props?.texts?.title,
      pretitle: props?.texts?.subtitle,
      description: props?.texts?.description,
      img: {
        src: this.getImageResponsive(props?.multimedia?.image?.[0]),
        alt: '',
      },
      button: Utils.mapButtons(props?.buttons)[0],
    } as AuroraThankI;
  }

  map404(props: any | undefined): Aurora404I {
    return {
      title: props.texts?.title,
      description: props.texts?.description,
      subtitle: props.texts?.subtitle,
      img: {
        src: this.getImageResponsive(props?.multimedia?.image?.[0]),
        alt: '',
      },
      button: Utils.mapButtons(props.buttons)[0],
    } as Aurora404I;
  }
}

export interface ImageResponse {
  path: ImageResponsePath;
  thumbnails: {
    375: ImageResponsePath;
    540: ImageResponsePath;
    768: ImageResponsePath;
    800: ImageResponsePath;
    1024: ImageResponsePath;
    1280: ImageResponsePath;
    1366: ImageResponsePath;
  };
  cover: number;
  tags?: any[];
  title?: string;
  pretitle?: string;
  subtitle?: string;
  ndTitle?: string;
  description?: string;
  links?: any[];
  buttons?: any[];
  alt?: string;
}

export interface ImageResponsePath {
  original: string;
  webp: string;
}

export interface ComponentsAddons {
  contactOffice?: any;
  menuSocialMedia?: { [k: string]: string }[];
  consultType?: ConsultTypeI;
}

export interface FilterOption {
  id?: number;
  name?: string;
  children?: FilterOption;
}
