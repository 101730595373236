import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Language, Page } from '../interfaces/site-config.interface';
import { ENDPOINTS } from './endpoints';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly http: HttpClient) {}

  private postFormData(
    data: any,
    url: string
  ): Observable<HttpResponse<void> | undefined | void> {
    return this.post(url, data);
  }

  postAuthLogin(): Observable<
    HttpResponse<LoginResponse> | LoginResponse | undefined
  > {
    const params = {
      email: environment.email,
      password: environment.password,
    };

    return this.post<LoginResponse>(ENDPOINTS.LOGIN, params);
  }

  getConfig(): Observable<
    GeneralConfigResponse | HttpResponse<GeneralConfigResponse> | undefined
  > {
    const language = localStorage.getItem('language');
    let languageId;
    if (language) {
      languageId = (JSON.parse(language) as Language).id;
    } else {
      languageId = 1;
    }
    return this.get<GeneralConfigResponse>(
      `${ENDPOINTS.GENERAL_CONFIG}${environment.siteId}/${languageId}/site`
    );
  }

  getMenu(
    languageId: string | number | undefined
  ): Observable<any | HttpResponse<any> | undefined> {
    return this.get<any>(
      `${ENDPOINTS.MENU_CONFIG}${environment.siteId}/${languageId}${ENDPOINTS.MENU}`
    );
  }

  getEngine(
    languageId: string | number | undefined,
    model: string | undefined,
    modelId: number | string | undefined
  ): Observable<any | HttpResponse<any> | undefined> {
    return this.get<any>(
      `/front/${environment.siteId}/${model}/${modelId}/${languageId}${ENDPOINTS.ENGINE}`
    );
  }

  getPage(
    pageId: string | number | undefined,
    languageId: string | number | undefined,
    itemId?: string | number,
    pageName?: string
  ): Observable<any | HttpResponse<any> | undefined> {
    return itemId
      ? this.get<any>(
          `${ENDPOINTS.PAGE}${environment.siteId}/${pageId}/${languageId}/${itemId}`,
          { referer: pageName }
        )
      : this.get<any>(
          `${ENDPOINTS.PAGE}${environment.siteId}/${pageId}/${languageId}`,
          { referer: pageName }
        );
  }

  getConsultType(
    languageId: string | number | undefined,
    model: string | undefined,
    modelId: number | string | undefined
  ): Observable<any | HttpResponse<any> | undefined> {
    return this.get<any>(
      `/${model}/${languageId}${ENDPOINTS.CONSULT_TYPE}`
    ) .pipe(
      catchError((err) => {
        return throwError(() => 
          new Error(typeof err === 'string' ? err : JSON.stringify(err))
        );
      })
    )
  }

  postNewsletterForm(
    data: any
  ): Observable<HttpResponse<void> | undefined | void> {
    return this.postFormData(data, ENDPOINTS.NEWSLETTER);
  }

  postContactForm(
    data: any
  ): Observable<HttpResponse<void> | undefined | void> {
    return this.postFormData(data, ENDPOINTS.CONTACT);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.token}`,
      'Access-Control-Allow-Origin': '*',
    });
  }

  private setQueryParams(queryParams?: {
    [key: string]: any;
  }): HttpParams | undefined {
    let params = new HttpParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          params = params.append(key, queryParams[key]);
        }
      }
      return params;
    }
    return undefined;
  }

  // Método genérico para realizar una solicitud GET
  private get<T>(
    url: string,
    queryParams?: { [key: string]: any }
  ): Observable<HttpResponse<T> | T | undefined> {
    let headers: HttpHeaders | undefined;
    if (environment.token) {
      headers = this.getHeaders();
    }
    const params = this.setQueryParams(queryParams);

    const fullUrl = environment.apiBaseUrl.concat(url);

    return this.http.get<T>(fullUrl, { headers, params }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
  }

  // Método genérico para realizar una solicitud POST
  private post<T>(
    url: string,
    data: any,
    queryParams?: { [key: string]: any }
  ): Observable<HttpResponse<T> | T | undefined> {
    let headers: HttpHeaders | undefined;
    if (environment.token) {
      headers = this.getHeaders();
    }

    const params = this.setQueryParams(queryParams);

    const fullUrl = environment.apiBaseUrl.concat(url);

    return this.http
      .post<HttpResponse<T>>(fullUrl, data, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        })
      );
  }
}

export interface GeneralConfigResponse {
  siteId: number;
  name: string;
  commonScripts: {
    [k: string]: string | null;
  };
  cdn: string;
  languages: {
    id: number;
    order: number;
    value: string;
    locate: string;
  }[];
  engine: {
    name: string;
    url: string;
    max_adults: number;
    max_childrens: number;
    max_babies: number;
    only_adults: number;
    max_age_children: number;
    max_age_babies: number;
    max_rooms: number;
    enable_children_age: number;
  };
  pages: Page[];
  favicons: {
    rel: string;
    href: string;
    sizes?: string;
  }[];
  logos: string[];
  socialMedia: {
    name: string;
    url: string;
  }[];
  address: {
    name: string;
    address: string;
    city: string;
    province: string;
    country: string;
    cp: string;
    lat: number | null;
    lon: number | null;
  };
}

export interface LoginResponse {
  abilities: string[];
  accessToken: string;
  role: string;
  token_type: string;
  user: { name: string; email: string };
}
