export const ENDPOINTS = {
  LOGIN: '/auth/login',
  GENERAL_CONFIG: '/front/sites/',
  MENU_CONFIG: '/front/sites/site/',
  PAGE: '/front/sites/site/page/',
  MENU: '/nav-items',
  ENGINE: '/engine',
  DESTINATIONS: '/front/destinations',
  CONTACT: '/front/contacts/contact',
  EMPLOYMENT: '/front/employments/employment',
  NEWSLETTER: '/front/newsletter',
  CONSULT_TYPE: '/consult-types',
};
