import { AuroraButtonI, DropdownOption } from 'ctt-aurora';
import { BodyComponent } from '../services/interfaces/page-config.interface';

export class Utils {
  // Función para convertir una cadena de snake_case a camelCase
  static snakeToCamel(snake: string): string {
    return snake.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
  }

  // Función para convertir las propiedades de un objeto de snake_case a camelCase
  static convertObjectKeysToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertObjectKeysToCamelCase(item));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce((result, key) => {
        const camelKey = this.snakeToCamel(key);
        result[camelKey] = this.convertObjectKeysToCamelCase(obj[key]);
        return result;
      }, {} as any);
    }
    return obj;
  }

  static formatLinkType(linkType: string): string | undefined {
    return linkType === 'external_link' || linkType === 'external'
      ? 'external'
      : linkType === 'internal_link' || linkType === 'internal'
      ? 'internal'
      : linkType;
  }

  static mapButtons(
    button: any | any[] | undefined | null,
    buttonType?: string
  ): (AuroraButtonI | undefined)[] {
    if (Array.isArray(button)) {
      return button?.map((btn) =>
        btn.label?.length
          ? ({
              type: buttonType ?? undefined,
              label: btn?.label,
              linkType: Utils.formatLinkType(btn?.linkType),
              url:
                btn.linkValue && typeof btn.linkValue === 'string'
                  ? btn?.linkValue?.charAt(btn?.linkValue?.length - 1) === '/'
                    ? btn?.linkValue?.slice(0, -1)
                    : btn?.linkValue
                  : undefined,
              icon: btn.icon ? btn?.icon : undefined,
            } as AuroraButtonI)
          : undefined
      );
    } else if (button) {
      return [
        button.label?.length
          ? ({
              ...button,
              linkType: Utils.formatLinkType(button?.linkType),
              url:
                button.linkValue && typeof button.linkValue === 'string'
                  ? button?.linkValue?.charAt(button?.linkValue?.length - 1) ===
                    '/'
                    ? button?.linkValue.slice(0, -1)
                    : button?.linkValue
                  : undefined,
            } as AuroraButtonI)
          : undefined,
      ];
    } else {
      return [];
    }
  }

  static fillDropdownOptions(
    max: number,
    zeroStart = true,
    numberStart = 0
  ): DropdownOption[] {
    let array: DropdownOption[] = [];
    let i = 0;
    while (i < max + 1) {
      if (i >= numberStart) {
        const value = i < 10 ? `0${i}` : `${i}`;
        array.push({ code: i, name: value } as DropdownOption);
      }
      i++;
    }

    if (!zeroStart && !numberStart) {
      array.shift();
    }

    return array;
  }

  static findComponent<T>(
    body: BodyComponent<T>[],
    name: string
  ): T | undefined {
    return body.find((component) => component && component.name.toLowerCase() === name)
      ?.props;
  }

  static convertToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  static normalizeText(text: string): string {
    return text
      ?.normalize('NFD') // Descompone los caracteres con acentos
      ?.replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
      ?.toLowerCase();
  }

  static changeLanguageInURL(url?: string, fixedLang?: string): string {
      return url && fixedLang ? url.replace(/\/(en|es|fr|de|it|pt)\//, `/${fixedLang}/`) : '';
    }
  
}
